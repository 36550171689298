import { DropDownType } from '../generics/classes/DropDownType';
import { IService } from '../serviceDetails/interfaces/IService';
import { Service } from '../serviceDetails/Service';
import { ISupplierZoneException } from './ISupplierZoneException';
import { ISupplierZonePrice } from './ISupplierZonePrice';

export class SupplierZonePrice implements ISupplierZonePrice {
	Id?: number;
	ServiceId?: number;
	ZoneId?: number;
	Lift = null;
	Haulage = null;
	Tonnage = null;
	MinTonnes = null;
	MaxTonnes = null;
	ExcessTonnage = null;
	OnDelivery = null;
	MinBags = null;
	OnCollection = null;
	Rental = null;
	Delivery = null;
	OnDemand = null;
	Fixed = null;
	EPA = null;
	Days = null;
	Frequencies = null;
	OverweightPricePerKg = null;
	Username = null;
	TimeUpdated = null;
	Status = 0;
	POA = null;
	Service: IService = new Service();
	DaysDropDown: DropDownType[] = [];
	FrequenciesDropDown: DropDownType[] = [];
	Exceptions: ISupplierZoneException[] = [];
}
