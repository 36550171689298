import { IContainerWasteSubStream } from '../interfaces/ContainerInfo/IContainerWasteSubStream';

export class ContainerWasteSubStream implements IContainerWasteSubStream {
	// Id's
	public Id = 0;

	public WasteStreamId = 0;

	// Props
	public Name = '';

	constructor(wasteStream?: any) {
		if (wasteStream) {
			this.InitialisePopulatedItem(wasteStream);
		}
	}

	public InitialisePopulatedItem(wasteSubStream: IContainerWasteSubStream) {
		this.Id = wasteSubStream.Id;
		this.WasteStreamId = wasteSubStream.WasteStreamId;
		this.Name = wasteSubStream.Name;
	}
}
