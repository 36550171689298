import { IContainerUnit } from '../interfaces/ContainerInfo/IContainerUnit';

export class ContainerUnit implements IContainerUnit {
	// Id's
	public Id = 0;

	// Props
	public Name = '';

	constructor(containerUnit?: IContainerUnit) {
		if (containerUnit) {
			this.InitialisePopulatedItem(containerUnit);
		}
	}

	public InitialisePopulatedItem(containerUnit: IContainerUnit) {
		this.Id = containerUnit.Id;
		this.Name = containerUnit.Name;
	}
}
