import { ContainerSize } from './containerDetails/ContainerSize';

import { IService } from './interfaces/IService';
import { IContainerWasteStream } from './interfaces/ContainerInfo/IContainerWasteStream';
import { IContainerType } from './interfaces/ContainerInfo/IContainerType';
import { IContainerSize } from './interfaces/ContainerInfo/IContainerSize';
import { IContainerUnit } from './interfaces/ContainerInfo/IContainerUnit';
import { IContainerWasteSubStream } from './interfaces/ContainerInfo/IContainerWasteSubStream';
import { ContainerWasteStream } from './containerDetails/ContainerWasteStream';
import { ContainerWasteSubStream } from './containerDetails/ContainerWasteSubStream';
import { ContainerType } from './containerDetails/ContainerType';
import { ContainerUnit } from './containerDetails/ContainerUnit';

export class Service implements IService {
	// ID's
	public Id = 0;

	// Classes
	public WasteStream: IContainerWasteStream = new ContainerWasteStream(null);
	public WasteSubStream: IContainerWasteSubStream = new ContainerWasteSubStream(
		null
	);
	public ContainerType: IContainerType = new ContainerType();
	public ContainerSize: IContainerSize = new ContainerSize();
	public Unit: IContainerUnit = new ContainerUnit();
	public Status = 0;

	// Props
	public AverageWeightKilograms = 0;
	public ServiceType = '';
	public ServiceDescription = '';
	public ServiceTypeName = '';
	public ServiceSubTypeName = '';

	public ServiceDetailsConcat = '';

	constructor(service: any = null) {
		// super();

		if (service) {
			this.InitialisePopulatedItem(service);
		} else {
			// this.CreateFormWithValidation(service ? service : this);
		}
	}

	public InitialisePopulatedItem(service: IService) {
		this.Id = service.Id;
		this.WasteStream = new ContainerWasteStream(service.WasteStream);
		this.WasteSubStream = new ContainerWasteSubStream(service.WasteSubStream);
		this.ContainerType = new ContainerType(service.ContainerType);
		this.ContainerSize = new ContainerSize(service.ContainerSize);
		this.Unit = new ContainerUnit(service.Unit);
		this.AverageWeightKilograms = service.AverageWeightKilograms;
		this.ServiceType = service.ServiceType;
		this.ServiceDescription = service.ServiceDescription;
		this.ServiceTypeName = service.ServiceTypeName;
		this.ServiceSubTypeName = service.ServiceSubTypeName;

		this.ConcatenateServiceDetails();
	}

	private ConcatenateServiceDetails() {
		if (this.WasteStream.Name) {
			this.ServiceDetailsConcat += this.WasteStream.Name;
		}

		if (this.WasteSubStream.Name) {
			this.ServiceDetailsConcat += ' - ' + this.WasteSubStream.Name;
		}

		if (
			this.ContainerType.Name &&
			this.WasteSubStream.Name !== this.ContainerType.Name
		) {
			this.ServiceDetailsConcat += ' - ' + this.ContainerType.Name;
		}

		if (this.ContainerSize.Name) {
			this.ServiceDetailsConcat += ' - ' + this.ContainerSize.Name;
		}

		if (this.Unit.Name) {
			this.ServiceDetailsConcat += this.Unit.Name;
		}
	}
}
