import { axiosBase } from '../base/baseAxios';
import { AxiosResponse } from 'axios';
import { ISupplierZonePrice } from '@/classes/supplierZones/ISupplierZonePrice';
import { SupplierZonePriceForm } from '@/classes/supplierZones/forms/supplierZonePriceForm';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { ISupplierZoneException } from '@/classes/supplierZones/ISupplierZoneException';
import { IService } from '@/classes/serviceDetails/interfaces/IService';
import { IContainerWasteStream } from '@/classes/serviceDetails/interfaces/ContainerInfo/IContainerWasteStream';

export class SupplierZoneServicesService {
	suppliersModule = SuppliersModule;

	public GetServiceFromServiceDetails(
		WasteStreamId: number,
		ContainerTypeId: number,
		WasteSubStreamId?: number,
		ContainerSizeId?: number,
		ContainerUnitId?: number
	): Promise<IService[]> {
		return new Promise((resolve, reject) => {
			let urlBuild = '/SupplierZones/Services';

			urlBuild = urlBuild += WasteStreamId
				? '?WasteStreamId=' + WasteStreamId
				: '';

			urlBuild = urlBuild += ContainerTypeId
				? '&ContainerTypeId=' + ContainerTypeId
				: '';

			if (WasteSubStreamId) {
				urlBuild = urlBuild += WasteSubStreamId
					? '&WasteSubStreamId=' + WasteSubStreamId
					: '';
			}

			if (ContainerSizeId) {
				urlBuild = urlBuild += ContainerSizeId
					? '&ContainerSizeId=' + ContainerSizeId
					: '';
			}

			if (ContainerUnitId) {
				urlBuild = urlBuild += ContainerUnitId
					? '&ContainerUnitId=' + ContainerUnitId
					: '';
			}

			axiosBase.get(urlBuild).then(
				(response: AxiosResponse<IService[]>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetContainerInfo(): Promise<IContainerWasteStream[]> {
		return new Promise((resolve, reject) => {
			axiosBase.get('/SupplierZones/Services/ContainerInfo').then(
				(response: AxiosResponse<IContainerWasteStream[]>) => {
					resolve(response.data);
				},
				(err: any) => {
					reject(err);
				}
			);
		});
	}

	public GetSupplierZonePricesGrouped(
		zoneId: number
	): Promise<[{ Name: string; ServicePrices: ISupplierZonePrice[] }]> {
		return new Promise((resolve, reject) => {
			axiosBase
				.get(`SupplierZones/Services/GroupedServicePrices?zoneId=${zoneId}`)
				.then(
					(
						response: AxiosResponse<
							[
								{
									Name: string;
									ServicePrices: ISupplierZonePrice[];
								}
							]
						>
					) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public SubmitPriceService(
		price: SupplierZonePriceForm
	): Promise<ISupplierZonePrice> {
		return new Promise((resolve, reject) => {
			axiosBase
				.post(
					`/SupplierZones/Services/${
						this.suppliersModule.GetSelectedSupplier.SupplierId
					}/${price.Id ? 'EditPrice' : 'AddPrice'}`,
					price
				)
				.then(
					(response: AxiosResponse<ISupplierZonePrice>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public AddSurchargeExclusion(
		exception: ISupplierZoneException
	): Promise<ISupplierZoneException> {
		return new Promise((resolve, reject) => {
			axiosBase
				.post(
					`/SupplierZones/Services/${this.suppliersModule.GetSelectedSupplier.SupplierId}/AddSurchargeExclusion`,
					exception
				)
				.then(
					(response: AxiosResponse<ISupplierZoneException>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}

	public DeleteSurchargeExclusion(
		surchargeOrExclusionId: number
	): Promise<ISupplierZoneException> {
		return new Promise((resolve, reject) => {
			axiosBase
				.delete(
					`/SupplierZones/Services/${this.suppliersModule.GetSelectedSupplier.SupplierId}/DeleteSurchargeOrExclusion?surchargeOrExclusionId=${surchargeOrExclusionId}`
				)
				.then(
					(response: AxiosResponse<ISupplierZoneException>) => {
						resolve(response.data);
					},
					(err: any) => {
						reject(err);
					}
				);
		});
	}
}
