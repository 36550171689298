import { IContainerSize } from '../interfaces/ContainerInfo/IContainerSize';
import { IContainerType } from '../interfaces/ContainerInfo/IContainerType';
import { ContainerSize } from './ContainerSize';

export class ContainerType implements IContainerType {
	// Id's
	public Id = 0;

	// Props
	public Name = '';
	ContainerSizes: IContainerSize[] = [];
	constructor(containerType: any = null) {
		if (containerType) {
			this.InitialisePopulatedItem(containerType);
		}
	}

	public InitialisePopulatedItem(containerType: IContainerType) {
		this.Id = containerType.Id;
		this.Name = containerType.Name;
		this.ContainerSizes =
			containerType.ContainerSizes.length > 0
				? containerType.ContainerSizes.map(xx => new ContainerSize(xx))
				: [];
	}
}
