import { IContainerType } from '../interfaces/ContainerInfo/IContainerType';
import { IContainerWasteStream } from '../interfaces/ContainerInfo/IContainerWasteStream';
import { IContainerWasteSubStream } from '../interfaces/ContainerInfo/IContainerWasteSubStream';
import { ContainerType } from './ContainerType';
import { ContainerWasteSubStream } from './ContainerWasteSubStream';

export class ContainerWasteStream implements IContainerWasteStream {
	// Id's
	public Id = 0;

	// Props
	public Name = '';
	public WasteSubStreams: IContainerWasteSubStream[] = [];
	public ContainerTypes: IContainerType[] = [];

	constructor(wasteStream?: any) {
		if (wasteStream) {
			this.InitialisePopulatedItem(wasteStream);
		}
	}
	public InitialisePopulatedItem(wasteStream: IContainerWasteStream) {
		this.Id = wasteStream.Id;
		this.Name = wasteStream.Name;
		this.ContainerTypes =
			wasteStream.ContainerTypes.length > 0
				? wasteStream.ContainerTypes.map(xx => new ContainerType(xx))
				: [];
		this.WasteSubStreams =
			wasteStream.WasteSubStreams.length > 0
				? wasteStream.WasteSubStreams.map(xx => new ContainerWasteSubStream(xx))
				: [];
	}
}
