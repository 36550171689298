import { IContainerSize } from '../interfaces/ContainerInfo/IContainerSize';
import { IContainerUnit } from '../interfaces/ContainerInfo/IContainerUnit';
import { ContainerUnit } from './ContainerUnit';

export class ContainerSize implements IContainerSize {
	// Id's
	public Id = 0;

	// Props
	public Name = '';

	ContainerUnits: IContainerUnit[] = [];

	constructor(containerSize: any = null) {
		if (containerSize) {
			this.InitialisePopulatedItem(containerSize);
		}
	}

	public InitialisePopulatedItem(containerSize: IContainerSize) {
		this.Id = containerSize.Id;
		this.Name = containerSize.Name;

		this.ContainerUnits =
			containerSize.ContainerUnits.length > 0
				? containerSize.ContainerUnits.map(xx => new ContainerUnit(xx))
				: [];
	}
}
